#root
{
  height: 100%;
}

.App {
  height: 100%;
}

.Weather-max {
  color: #92270c !important;
}

.Weather-min {
  color: #1352cf !important;
}

.weather-content
{
  min-height: 200px;
}